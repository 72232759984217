<template>
    <div class="content-main">
        <!-- 会议管理 > 会议分析 -->
        <div v-if="!errorShow">
            <loading :loadingShow="loadingShow"></loading>
            <div v-if="!loadingShow">
                <div class="filter-wrap">
                    <expand-filter
                        :formData="formData"
                        :closeWidth="1360"
                        margin-bottom="0"
                        @clickBtn="clickBtn"
                        @changeBtnFormType="changeBtnFormType"
                        @changeCascader="changeCascader"
                    ></expand-filter>
                    <div class="button-line"  v-hasPermi="['schoolMeetingAnalysis:add']"></div>
                    <el-button
                        v-hasPermi="['schoolMeetingAnalysis:add']"
                        type="primary"
                        @click="dialogAddOpen()"
                    >添加</el-button>
                    <el-button
                        v-hasPermi="['schoolMeetingAnalysis:delete:batch']"
                        type="danger"
                        @click="acts()"
                    >批量删除</el-button>
                </div>
                <div class="table-paging">
                    <table-data :config="table_config" :to-top="false" :tableData="table_data" v-loading="loadingTable"
                        backgroundHeader="#FAFBFC" :headerStyle="{ height: '50px' }" :rowStyle="{ height: '50px' }"
                        ref="table" @handleSelectionChange="handleSelectionChange">
                        <template #title="{ data }">
                            <span v-if="detailShow" style="width:200px;color: #3C7FFF;cursor: pointer;over-flow:hidden;white-space:nowrap;text-overflow: ellipsis;" @click="tableBtnClick(data, 'primary')">{{ data.title }}</span>
                            <span style="color: #595959;cursor: pointer" v-else>{{ data.title }}</span>
                        </template>
                        <template #startDate="{ data }">
                            <!-- <span v-if="data.startTime.slice(0, 4)==yearsDeta">{{ data.startTime.slice(5, 10) }}</span>
                            <span v-else>{{ data.startTime.slice(0, 10) }}</span> -->
                            <span>{{ data.startDate }}</span>
                        </template>
                        <template #startTime="{ data }">
                            <!-- <span v-if="data.status==0">{{ data.appointTime }}</span>
                            <span v-else-if="data.status==1">{{ data.startTime.slice(11,16) }}</span>
                            <span v-else-if="data.status==2">{{ data.startTime.slice(11,22) }}</span> -->
                            <span>{{ data.startTime }}</span>
                        </template>
                        <template v-slot:operation="slotData">
                            <el-button type="text" v-has-permi="['schoolMeetingAnalysis:screen']"
                                @click="handleScreen(slotData.data)">录屏</el-button>
                            <el-button v-has-permi="['schoolMeetingAnalysis:summary']" @click="handleSummary(slotData.data)"
                                type="text">纪要</el-button>
                            <el-button v-hasPermi="['schoolMeetingAnalysis:edit']"
                                @click="tableBtnClick(slotData.data, 'edit')" type="text">编辑</el-button>
                            <!-- <el-button
                                v-hasPermi="['schoolMeetingAnalysis:info']" size="mini" type="primary"
                                @click="tableBtnClick(slotData.data, 'primary')"
                            >详情</el-button> -->
                            <el-button v-hasPermi="['schoolMeetingAnalysis:del']" type="text"
                                @click="tableBtnClick(slotData.data, 'danger')">删除</el-button>


                        </template>
                    </table-data>
                    <pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow"
                        @pagination="changeGetList()" />
                </div>
                <!-- 添加 -->
                <dialog-wrapper :dialogObj="dialogAddObj" @handleClose="dialogAddClose">
                    <el-form class="common-form" :model="dialogAddForm" label-width="100px" :rules="dialogAddRules" ref="dialogAddRef">
                        <el-form-item label="标题" prop="title">
                            <el-input v-model="dialogAddForm.title" maxlength="20" show-word-limit placeholder="请输入会议标题"
                                class="input-width-medium"></el-input>
                        </el-form-item>
                        <el-form-item label="类型" prop="typeId">
                            <el-select v-model="dialogAddForm.typeId" clearable filterable placeholder="请选择会议类型"
                                class="input-width-medium">
                                <el-option v-for="item in meetingType" :value="item.value" :label="item.label"
                                    :key="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <div class="spanTip">不同类型的会议，录屏文件保存期限不同</div>
                        <el-form-item label="科目" prop="subjectId">
                            <el-select v-model="dialogAddForm.subjectId" @change="changeSubject" clearable filterable
                                placeholder="请输入科目" class="input-width-medium">
                                <el-option v-for="item in subjectType" :value="item.value" :label="item.label"
                                    :key="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="发起时间" prop="startTime">
                            <el-date-picker v-model="dialogAddForm.startTime" :picker-options="options"
                                popper-class="no_now" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
                                placeholder="请选择会议发起时间" class="input-width-medium input_inner_right">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item label="会议时长" prop="meetingDuration">
                            <el-input class="input-width-medium" v-model="dialogAddForm.meetingDuration" placeholder="请输入会议时长（HH:mm:ss 格式）">
                            </el-input>
                        </el-form-item>
                        <el-form-item label="发起人" prop="conferenceSponsor">
                            <el-input v-model="dialogAddForm.conferenceSponsor" maxlength="20" show-word-limit
                                placeholder="请输入会议发起人" class="input-width-medium"></el-input>
                        </el-form-item>
                        <el-form-item label="年级" prop="gradeId">
                            <el-cascader
                                ref="cascaderAddFormGradeRef"
                                class="input-width-medium"
                                v-model="dialogAddForm.gradeId"
                                :props="{
                                    label: 'name',
                                    multiple: false,
                                    value: 'id',
                                    checkStrictly: true,
                                    children: 'child',
                                    emitPath:false
                                }"
                                :options="gradeOptions"
                                collapse-tags
                                :placeholder="'请选择年级'"
                                :clearable="true"
                                filterable
                                @change="changeGrade"
                            ></el-cascader>
                            <!-- <el-select v-model="dialogAddForm.gradeId" @change="changeGrade" clearable filterable
                                placeholder="请输入年级" class="input-width-medium">
                                <el-option v-for="item in gradeOptions" :value="item.value" :label="item.label"
                                    :key="item.value"></el-option>
                            </el-select> -->

                        </el-form-item>
                        <el-form-item label="参会者" prop="files">
                            <el-upload action="/api/school/schoolMeetingAnalysis/importMeeting" :headers="dialogAddHeaders"
                                :data="dialogAddFormComp" :before-upload="uploadBefore" :on-success="uploadSuccess"
                                :on-change="uploadChange" accept=".xlsx, .xls" :auto-upload="false" :limit="1"
                                ref="uploadRef">
                                <el-button type="enquiry" style="margin-left: 0; margin-right: 20px;"><i
                                        class="el-icon-upload2" slot="trigger"></i> 文件上传</el-button>
                                <el-button @click="downloadTemplate" type="enquiry" slot="tip"
                                    style="margin-left: 0; margin-right: 20px;"><i class="el-icon-download"></i> 下载模板
                                </el-button>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="会议纪要" prop="fileList">
                            <el-upload :action="action" :headers="dialogAddHeaders" :data="dialogAddFormComp"
                                :before-upload="uploadBefore1" ref="uploadRef1" :on-success="uploadSuccess1"
                                accept=".doc,.docx,.xls,.xlsx,.wps,.txt,.pdf,.jpg,.png" :on-exceed="handleExceed"
                                :limit="10">
                                <el-button type="enquiry" style="margin-left: 0; margin-right: 20px;"><i
                                        class="el-icon-upload2" slot="trigger"></i> 文件上传</el-button>
                                <div slot="tip" style="color:#c0c4cc;font-size: 12px; width: 452px;">
                                    上传格式：txt、doc、wps、docx、xls、xlsx、pdf、jpg、png, 单个文档50M以内, 最多上传10个</div>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="录屏" prop="videoList">
                            <el-upload :action="action" :headers="dialogAddHeaders" :data="dialogAddFormComp"
                                :before-upload="uploadBefore2" ref="uploadRef2" :on-success="uploadSuccess2"
                                accept=".wmv,.asf,.rm,.rmvb,.mov,.avi,.mp4" :on-exceed="handleExceed" :limit="10">
                                <el-button type="enquiry" style="margin-left: 0; margin-right: 20px;"><i
                                        class="el-icon-upload2" slot="trigger"></i> 文件上传</el-button>
                                <div slot="tip" style="color:#c0c4cc;font-size: 12px;width: 452px;">上传格式：wmv、asf、rm、rmvb、mov、mp4、avi,
                                    单个视频600M以内, 最多上传10个</div>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                    <div style="text-align: right;">
                        <el-button @click="dialogAddClose" type="enquiry">取消</el-button>
                        <el-button @click="dialogAddClick" type="primary">确定</el-button>
                    </div>
                </dialog-wrapper>
                <!-- 纪要 -->
                <dialog-wrapper :dialogObj="dialogObjSummary" @handleClose="handleCloseSummary">
                    <el-form :model="dialogSummary" ref="dialogSummaryRef">
                        <el-form-item prop="file">
                            <el-upload action="/api/school/schoolMeetingAttachment/uploadFile"
                                :headers="dialogSummaryHeaders" :data="dialogSummaryFormComp" :before-upload="uploadBefore3"
                                :on-success="uploadSuccess3" :on-change="uploadChange3"
                                accept=".doc,.docx,.xls,.xlsx,.wps,.txt,.pdf,.jpg,.png" :limit="10" :show-file-list="true"
                                ref="uploadRef">
                                <el-button type="enquiry" style="margin-left: 0; margin-right: 20px;"><i
                                        class="el-icon-upload2" slot="trigger"></i> 上传文件</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                    <div>
                        <table-data id="table" v-loading="loadingTable" :config="tableConfig" :table-data="tableData"
                            backgroundHeader="#FAFBFC" headerColor='#595959'>
                            <template v-slot:operation="slotData">
                                <el-button size="mini" @click="handlePreview(slotData.data)" type="primary">预览</el-button>
                                <el-button size="mini" @click="downlad(slotData.data.url, slotData.data.name)"
                                    type="primary">下载文件</el-button>
                                <el-button size="mini" v-if="slotData.data.autoUpload == '0'"
                                    @click="handleDel(slotData.data)" type="danger">删除</el-button>
                            </template>
                        </table-data>
                    </div>
                </dialog-wrapper>
                <!-- 录屏 -->
                <dialog-wrapper :dialogObj="dialogObjScreen" @handleClose="handleCloseScreen">
                    <el-form :model="dialogScreen" ref="dialogScreenRef">
                        <el-form-item prop="file">
                            <el-upload action="/api/school/schoolMeetingAttachment/uploadFile"
                                :headers="dialogScreenHeaders" :data="dialogScreenFormComp" :before-upload="uploadBefore4"
                                :on-success="uploadSuccess4" :on-change="uploadChange4"
                                accept=".wmv,.asf,.rm,.rmvb,.mov,.avi,.mp4" :limit="10" :show-file-list="true"
                                ref="uploadRef">
                                <el-button type="enquiry" style="margin-left: 0; margin-right: 20px;"><i
                                        class="el-icon-upload2" slot="trigger"></i> 上传视频</el-button>
                                <div slot="tip"
                                    style="background-color: #fff;display: flex;align-items: center; position:relative">
                                    <img :src="require('@/assets/images/tip_icon.png')"
                                        style="width: 14px; height: 14px; margin-right: 7px;" alt="">
                                    <span
                                        style="font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #6D6F73;">asf、avi、rmvb、mov、wmv格式的文件不支持预览</span>
                                </div>
                            </el-upload>
                        </el-form-item>
                    </el-form>
                    <div>
                        <table-data id="table" v-loading="loadingTable" :config="tablScreenConfig"
                            :table-data="tableScreenData" backgroundHeader="#FAFBFC" headerColor='#595959'>
                            <template v-slot:operation="slotData">
                                <el-button size="mini" v-if="slotData.data.sign == '1'&&slotData.data.isInvalid == '0'"
                                    @click="handlePreviewVideo(slotData.data)" type="primary">预览</el-button>
                                <!-- <a :href="slotData.data.url"
                                    style="width: 50px;margin-left: 12px;padding:7px 8px;
                                    color:#3C7FFF;background-color: #EDF4FA;border-radius: 2px;display: inline;">下载文件</a> -->
                                <xk-button v-if="slotData.data.isInvalid == '0'" size="mini" @click="downlad(slotData.data.url, slotData.data.name)"
                                    type="primary">下载文件</xk-button>
                                <xk-button size="mini" v-if="slotData.data.autoUpload == '0'"
                                    @click="handleScreenDel(slotData.data)" type="danger">删除</xk-button>
                            </template>
                        </table-data>
                    </div>
                    <el-dialog title="视频预览" :visible.sync="dialogVisible" width="800" :before-close="closePreviewVideo"
                        append-to-body center>
                        <div id="mse" style="margin:auto"></div>
                    </el-dialog>
                </dialog-wrapper>
            </div>
        </div>
        <error v-else :errorShow="errorShow"></error>
    </div>
</template>

<script>
import { downloadFile, cleanTree, flattenAndUnique } from "@/libs/utils";
import { Loading, Pagination, DialogWrapper, debounce } from "common-local"
import TableData from "./Sub/TableData"
import { getToken } from "@/libs/auth"
import ExpandFilter from "./Sub/ExpandFilter";
import { MeetingListModel } from "@/models/MeetingList"
import { SummaryTeacherBase } from "@/models/SummaryTeacherBase.js";
import { mapState } from "vuex";
import Player from 'xgplayer';
import onResize from "@/mixins/onResize"
import { filePreview } from '@/libs/staticResources.js'
import RecordsList from '@/libs/pageListOptimal.js';

export default {
    mixins: [onResize],
    components: { Loading, TableData, Pagination, DialogWrapper, ExpandFilter, },
    data() {
        return {
            tableListSel: [], // 批量删除
            // 当前年
            yearsDeta: new Date().getFullYear(),
            loadingShow: false,
            errorShow: false,
            loadingTable: false,
            subjectList: [],
            // gradeList: [],
            fileUrl: '',
            videoUrl: '',
            options: {
                disabledDate(time) {
                    // var now = new Date()
                    // now.setHours(0, 0, 0, 0);
                    return time.getTime() >= Date.now()
                },
                selectableRange: '00:00:00 - 23:59:59'
            },
            dialogVisible: false,
            listQuery: {
                schoolId: '',
                productId: 'cloud_campus',
                pageNum: 1,
                pageRow: 20,
                title: "",
                subjectId: "",
                startTime: '',
                gradeId: "",
                conferenceSponsor: '',
                typeId: '',
            },
            tableData: [],//会议纪要数据
            tableScreenData: [],//录屏数据
            meetingType: [], //会议类型
            gradeOptions: [],//年级类型
            subjectType: [],//科目
            // 头部筛选
            formData: {
                btnFormType: true,
                formInline: {
                    teacherName: "",
                },
                data: [
                    {
                        type: "input",
                        label: "",
                        value: "",
                        placeholder: "标题",
                        key: "title"
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "类型",
                        key: "typeId",
                        list: []
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "方式",
                        key: "mode",
                        list: [
                            // {
                            //     label: "方式",
                            //     value: "",
                            // },
                            {
                                label: "自主发起",
                                value: 1,
                            },
                            {
                                label: "手动添加",
                                value: 5,
                            },
                            {
                                label: "预约",
                                value: 2,
                            }
                        ]
                    },
                    {
                        type: "select",
                        label: "",
                        placeholder: "发起人",
                        key: "sponsorUnionId",
                        value: "",
                        list: []
                    },
                    {
                        type: "select",
                        label: "",
                        value: "",
                        placeholder: "科目",
                        key: "subjectId",
                        list: []
                    },
                    // {
                    //     type: "noRestrictionsDate",
                    //     label: "",
                    //     value: "",
                    //     placeholder: "会议开始时间",
                    //     key: "startTime"
                    // },
                    {
                        type: "datePick",
                        label: "",
                        value: [],
                        startPlaceholder: "开始时间",
                        endPlaceholder: "结束时间",
                        key: "selectTime",
                    },
                    {
                        type: "cascader",
						label: "",
						value: [],
						placeholder: "年级",
						key: "organIds",
						list: [],
						cascaderProps:{
							label: "name",
							multiple: true,
							value: "id",
							children: 'child',
							checkStrictly: true,
							emitPath:false
						}
                    },
                ],
                btnList: [
                    {
                        type: "primary",
                        text: "查询",
                        fn: "primary",
                        auth: ["schoolMeetingAnalysis:list"]
                    },
                    {
                        type: "enquiry",
                        text: "重置",
                        fn: "reset",
                        auth: ["schoolMeetingAnalysis:add"]
                    },
                ]
            },
            table_config: {
                thead: [
                    {
                        label: "标题",
                        slotName: 'title',
                        type: 'slot',
                        // prop: 'title',
                        labelWidth: "240",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "类型",
                        prop: "typeName",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "会议日期",
                        labelWidth: "160",
                        align: 'center',
                        type: "slot",
                        slotName: "startDate",
                        // callBack: (row) => {
                        //     return row.startTime.slice(0, 10)
                        // }
                    },
                    {
                        label: "会议时间",
                        labelWidth: "160",
                        align: 'center',
                        type: "slot",
                        slotName: "startTime",
                        // callBack: (row) => {
                        //     return row.startTime.slice(0, 16)
                        // }
                    },
                    // {
                    //     label: "会议时长",
                    //     prop: "meetingDuration",
                    //     align: 'center',
                    //     type: "function",
                    //     callBack: (row) => {
                    //         if (row.meetingDuration) {
                    //             return row.meetingDuration.slice(0, row.meetingDuration.length - 3)
                    //         }
                    //     }
                    // },
                    {
                        label: "总人数",
                        prop: "meetingNumber",
                        align: 'center',
                        labelWidth: "120"
                    },
                    {
                        label: "已入会",
                        prop: "joinedNumber",
                        align: 'center',
                        labelWidth: "100"
                    },
                    {
                        label: "时长不足",
                        labelDescription: "参会时长不足人数",
                        prop: "notEnoughTimeNumber",
                        align: 'center',
                        labelWidth: "150"
                    },
                    {
                        label: "未入会",
                        prop: "noJoinedNumber",
                        align: 'center',
                        labelWidth: "100"
                    },
                    {
                        label: "参会比例",
                        prop: "participationRatio",
                        align: 'center',
                    },
                    {
                        label: "发起人",
                        prop: "conferenceSponsor",
                        type: "popoverText",
                        labelWidth: "100",
                        align: 'center',
                    },
                    {
                        label: "科目",
                        prop: "subject",
                        type: "popoverText",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "年级",
                        prop: "grade",
                        type: "function",
                        align: 'center',
                        className: 'text-spacing-reduction',
                        callBack(row) {
                            let parentName = "";
                            let gradeName = "";
                            parentName = row.parentName && row.parentName != "-" ? `${row.parentName}/` : "";
    
                            gradeName = row.grade ? row.grade : "";
                            return `<span>${parentName}${gradeName}</span>`
                            
                        },
                    },
                    {
                        label: "方式",
                        prop: "modeName",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    },
                    {
                        label: "操作",
                        type: "slot",
                        slotName: "operation",
                        labelWidth: "160",
                        align: 'center',
                        className: 'text-spacing-reduction'
                    }
                ],
                check: true,
                height: ""
            },
            // 会议纪要表格配置数据
            tableConfig: {
                check: false,
                height: '',
                thead: [
                    {
                        label: '文件名称',
                        prop: 'name'
                    },
                    {
                        label: '上传时间',
                        prop: 'createTime',
                        labelWidth: '180px',
                        align: "left",
                        type: "function",
                        callBack: (row) => {
                            return row.createTime.slice(0, 16)
                        }
                    },
                    {
                        label: '上传人',
                        prop: 'createByName'
                    },
                    // {
                    //     label: '失效日期',
                    //     // prop: 'expirationTime',
                    //     type: 'function',
                    //     callBack(row) {
                    //         return row.expirationTime?.slice(0,10)
                    //     }
                    // },
                    // {
                    //     label: '状态',
                    //     // prop: 'isInvalid',
                    //     type: 'Invalid',
                    //     callBack(row) {
                    //         if (row.isInvalid == '0') {
                    //             return '正常'
                    //         } else if (row.isInvalid == '1') {
                    //             return " 已失效"
                    //         } else {
                    //             return ''
                    //         }
                    //     }
                    // },
                    {
                        label: '操作',
                        type: 'slot',
                        slotName: 'operation',
                        labelWidth: '240'
                    }
                ]
            },
            //录屏功能表格配置数据
            tablScreenConfig: {
                check: false,
                height: '',
                thead: [
                    {
                        label: '文件名称',
                        prop: 'name'
                    },
                    {
                        label: '上传时间',
                        // prop: 'createTime',
                        // type: 'popoverText',
                        labelWidth: '150px',
                        type: 'function',
                        callBack(row) {
                            return row.createTime.slice(0,16)
                        }
                    },
                    {
                        label: '上传人',
                        prop: 'createByName',
                    },
                    {
                        label: '失效日期',
                        // prop: 'expirationTime',
                        type: 'function',
                        callBack(row) {
                            return row.expirationTime?.slice(0,10)
                        }
                    },
                    {
                        label: '状态',
                        // prop: 'isInvalid',
                        type: 'Invalid',
                        callBack(row) {
                            if (row.isInvalid == '0') {
                                return '正常'
                            } else if (row.isInvalid == '1') {
                                return '已失效'
                            } else {
                                return ''
                            }
                        }
                    },
                    {
                        label: '操作',
                        type: 'slot',
                        slotName: 'operation',
                        labelWidth: '200'
                    },
                ]
            },
            table_data: [],
            total: 0,
            dialogAddHeaders: {
                Authorization: getToken()
            },
            dialogSummaryHeaders: {
                Authorization: getToken()
            },
            dialogScreenHeaders: {
                Authorization: getToken()
            },
            dialogObjSummary: { //会议纪要
                title: '会议纪要',
                dialogVisible: false,
                width: '900px',
            },
            dialogObjScreen: { //录屏
                title: '录屏',
                dialogVisible: false,
                width: '900px',
            },
            dialogAddForm: {
                productId: "cloud_campus",
                title: "",//会议标题
                subject: "",//科目
                subjectId: '',//科目id
                startTime: "",//开始时间
                meetingDuration: "",//会议时长
                conferenceSponsor: "",//发起人
                grade: "",//年级
                gradeId: '',//年级id
                parentId: "",//当前选中年级/级部的上级id
                parentName: "",//当前选中年级/级部的上级名称
                typeId: '',//会议类型
                files: [],
                fileList: "",//会议纪要
                videoList: "",//录屏
            },
            dialogAddRules: {
                title: [{ required: true, message: "请输入会议标题", trigger: "blur" }],
                typeId: [{ required: true, message: "请选择会议类型", trigger: "blur" }],
                startTime: [{ required: true, message: "请选择会议发起时间", trigger: "change" }],
                meetingDuration: [
                    { required: true, message: "请输入会议时长", trigger: "blur" },
                    { pattern: /([0-1][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/, message: "请输入 HH:mm:ss 格式的会议时长", trigger: "blur" },
                ],
                conferenceSponsor: [{ required: true, message: "请输入会议发起人", trigger: "blur" }],
                // grade: [{ required: true, message: "请输入年级", trigger: "blur" }],
                files: [{ required: true, message: "请选择视频会议参会者文件", trigger: "blur" }],
            },
            dialogAddObj: {
                title: "添加会议",
                dialogVisible: false,
                width: "auto"
            },
            //纪要
            dialogSummary: {
                file: [],
                meetingId: '',//会议id
                type: '1'
            },
            //录屏
            dialogScreen: {
                file: [],
                meetingId: '',//会议id
                type: '2'
            },
            arrFileList: [],
            arrVideoList: [],
            detailShow: true,
            dataList: [],
            player: "",
        }
    },
    watch: {
        'dialogAddForm.startTime'(selectTime) {
            const date = new Date(new Date(selectTime).setHours(0, 0, 0, 0)).getTime()
            const today = new Date(new Date().setHours(0, 0, 0, 0)).getTime()
            const curTime = new Date()
            if (date >= today) {
                // 当选择的日期就是当天的时候，这个时候就要限制时间应当小于此时此刻的时分秒
                this.options.selectableRange = `'00:00:00 - ${String(curTime.getHours()).padStart(2, '0')}:${String(curTime.getMinutes()).padStart(2, '0')}:${String(curTime.getSeconds()).padStart(2, '0')}`

            } else {
                // 当选择的日期小于当天的时候，这时需要把时分秒的限制放开，否则不能选择
                this.options.selectableRange = '00:00:00 - 23:59:59'
            }
        }

    },
    async created() {
        // await this.getFilterItems()
        // await this.getTableHeaders()
        this.init()
        this.getSponsorPeopleList();
        this.getTableList()
        this.getSubjectList()
        this.getGradList()
        this.getTypes()
    },
    computed: {
        ...mapState({
            permissions: (state) => state.permissions
        }),
        dialogAddFormComp() {
            return {
                schoolId: this.$store.state.schoolId,
                ...this.dialogAddForm
            }
        },
        dialogSummaryFormComp() {
            return {
                ...this.dialogSummary
            }
        },
        dialogScreenFormComp() {
            return {
                ...this.dialogScreen
            }
        },
        action() {
            return '/api/school/common/uploadFile'
        },
    },
    methods: {
        // 获取筛选项
        async getFilterItems() {
            const summaryTeacherBase = new SummaryTeacherBase();
            let res = await summaryTeacherBase.getUserPreferences({
                menuId: this.$route.meta.menuId,
                configType: '1'
            })
            console.log("[getFilterItems]res:", res)
        },
        // 获取动态表头
        async getTableHeaders() {

        },
        init() {
            let recordsList = new RecordsList();
            if(recordsList.get(this.$route.name)){
                this.formData = recordsList.get(this.$route.name).formData;
                this.listQuery = recordsList.get(this.$route.name).listQuery;
            }
            console.log('this.listQuery',this.listQuery);
            console.log(this.permissions, '有值吗');
            if (this.permissions.indexOf('schoolMeetingAnalysis:info') == -1) {
                this.detailShow = false
            }
        },
        // 选中
        handleSelectionChange(data) {
            console.log('删除选中的data', data);
            this.tableListSel = data;
        },
        // 批量删除
        acts: debounce(function () {
            if (this.tableListSel.length === 0) {
                return this.$message.warning("未选择要删除的会议！");
            }
            this.$confirm(
                `选中的会议将永久删除，是否确认?`,
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                },
            )
                .then(() => {
                    console.log('this.tableListSel.map((it) => it.id)',this.tableListSel.map((it) => it.id));
                    this._fet("/school/schoolMeetingAnalysis/delMeetingBatch", {
                        ids: this.tableListSel.map((it) => it.id),
                    }).then((r) => {
                        if (r.data.code == 200) {
                            this.$message.success("删除成功");
                            this.getTableList();
                        } else {
                            this.$message.error(r.data.msg);
                        }
                    });
                })
                .catch(() => {});
        }, 200),
        changeGrade(val) {
            // this.gradeOptions.forEach((it) => {
            //     if (this.dialogAddForm.gradeId == it.value) {
            //         this.dialogAddForm.grade = it.label
            //     }
            // })

            // cascaderAddFormGradeRef
            
            if(!val){
                this.dialogAddForm.gradeId = "";
                this.dialogAddForm.grade = "";
                this.dialogAddForm.parentId = "";
                this.dialogAddForm.parentName = "";
                return
            }

            let nodes = this.$refs.cascaderAddFormGradeRef.getCheckedNodes();

            console.log(nodes);
            this.dialogAddForm.gradeId = nodes[0].value.toString();
            this.dialogAddForm.grade = nodes[0].label.toString();

            if(nodes[0].parent) {
                let parent = nodes[0].parent
                this.dialogAddForm.parentId = parent.value.toString();
                this.dialogAddForm.parentName = parent.label.toString();;
            } else {
                this.dialogAddForm.parentId = "";
                this.dialogAddForm.parentName = "";
                return
            }

        },
        changeSubject() {
            this.subjectType.forEach((it) => {
                if (this.dialogAddForm.subjectId == it.value) {
                    this.dialogAddForm.subject = it.label
                }
            })
        },
        /**
         * @Description: 获取发起人列表数据
         * @DoWhat: isDing 是否为钉钉用户，1是，0否
         * @UseScenarios:
         * @Attention:
         * @Author: xgz
         * @Date: 2023-2-9 08:58:17
         */
        getSponsorPeopleList(teacherName) {
            teacherName = teacherName || "";
            this._fet("/school/schoolTeacher/listByConditionNormal", {
                isDing: "1",
                teacherName: teacherName,
                schoolId: this.$store.state.schoolId
            }).then((res) => {
                if (res.data.code === "200") {
                    this.dataList = res.data.data;
                    this.formData.data.forEach((item) => {
                        if (item.key === "sponsorUnionId") {
                            item.list = this.dataList.map(i => ({
                                label: i.teacherName,
                                value: i.dingUnionId
                            }))
                            // item.list.unshift({
                            // 	label: '发起人',
                            // 	value: ""
                            // })
                        }
                    })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 获取类型
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-03 17:18:58
         */
        getTypes() {
            this._fet("/school/schoolDictValue/getDictValueList", {
                dickKeyList: ["meetingType"],
                schoolId: this.$store.state.schoolId,
            }).then((res) => {
                if (res.status === 0) {
                    this.meetingType = res.data.map(i => ({ label: i.name+'('+i.remark+')', value: i.id }))
                    this.formData.data.forEach((item) => {
                        if (item.key === 'typeId') {
                            item.list = res.data.map(i => ({ label: i.name+'('+i.remark+')', value: i.id }))
                            // item.list.unshift({label: '类型', value: ''})
                        }
                    })
                }
                console.log(this.formData.data,'this.formData.data')
            })
        },
        /**
         * @Description: 年级
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-04 13:57:06
         */
        getGradList() {
            let obj = {
					schoolId: this.$store.state.schoolId,
				};
				// Object.assign(obj, {jobScope: ["1", "2", "3"]});
				this._fet(
					"/school/schoolOrgan/getGradeOfTree",
					obj,
				).then((res) => {
					if (res.data.code === "200") {
                        let options = cleanTree(res.data.data,'child');
                        this.gradeOptions = options
						this.formData.data.forEach((item) => {
							if (item.key === "organIds") {
								item.list = options;
							}
						});
					} else {
						this.$message.error("数据请求失败，请重试");
					}
				});
        },
        /**
         * @Description: 添加会议弹窗关闭回调
         * @DoWhat: 关闭弹窗，重置表单校验，初始化表单数据
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-08 11:43:48
         */
        dialogAddClose() {
            this.arrFileList = []
            this.arrVideoList = []
            this.$refs.uploadRef.clearFiles()
            this.$refs.uploadRef1.clearFiles()
            this.$refs.uploadRef2.clearFiles()
            this.dialogAddForm = this.$options.data.call().dialogAddForm
            this.$refs.dialogAddRef.resetFields()
            this.dialogAddObj.dialogVisible = false
        },
        /**
         * @Description: 添加会议弹窗打开回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-08 11:45:58
         */
        dialogAddOpen() {
            this.dialogAddObj.dialogVisible = true
        },
        /**
         * @Description: 添加会议确定按钮点击
         * @DoWhat: 校验必填项，请求添加会议接口，处理请求返回
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-08 16:57:22
         */
        dialogAddClick() {
            this.$refs.dialogAddRef.validate((valid) => {
                if (valid) {
                    this.$refs.uploadRef.submit()
                    this.arrFileList = []
                    this.arrVideoList = []
                } else {
                    return false;
                }
            })
        },
        /**
         * @Description: 下载模板
         * @DoWhat: 请求下载模板接口获取下载地址，访问下载地址获取文件
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-08 16:28:10
         */
        downloadTemplate() {
            this._get("/school/schoolMeetingAnalysis/template",).then(res => {
                if (res.data.code === "200") {
                    downloadFile({ url: res.data.data, method: "get" })
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 选择上传文件回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-09 10:15:56
         */
        uploadBefore(file) {
            let isImg = [".xlsx", ".xls"].includes(file.name.substring(file.name.lastIndexOf(".")))
            let isLt10M = file.size / 1024 / 1024 < 10
            if (!isImg) {
                this.$message.error("上传文件格式仅支持xlsx和xls！")
                return false
            } else if (!isLt10M) {
                this.$message.error("上传文件的大小不能超过10M！")
                return false
            }
        },
        /**
         * @Description: 文件上传成功回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-09 10:19:06
         */
        uploadSuccess(res, file) {
            if (res.code === "200") {
                this.getTableList()
                this.dialogAddClose()
            } else if (res.code === "400") {
                this.$message.error(res.msg)
                this.$refs.uploadRef.clearFiles()
                this.dialogAddForm.files = []
            } else if (res && res.msg) {
                this.$refs.uploadRef.clearFiles()
                this.dialogAddForm.files = []
                this.$confirm(res.msg, "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    downloadFile({
                        url: "/school/schoolMeetingAnalysis/errorExport",
                        method: "post",
                        fileName: "导入错误信息表.xlsx",
                        form: { paramData: res.info.paramData }
                    })
                }).catch(() => {
                })
            }
        },
        /**
         * @Description: 选择文件状态切换回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-09 11:00:25
         */
        uploadChange(file, fileList) {
            this.dialogAddForm.files = fileList
        },
        handleExceed() {
            this.$message.warning("最多只能上传10个文件")
        },
        /**
        * @Description: 添加-会议纪要-上传成功回调
        * @DoWhat:
        * @UseScenarios:
        * @Attention:
        * @Author: xwn
        * @Date: 2023-01-02 20:54:11
        */
        uploadSuccess1(res, file) {
            if (res.code === "200") {
                this.arrFileList.push({
                    name: file.name,
                    url: res.data.url
                })
                this.dialogAddForm.fileList = JSON.stringify(this.arrFileList)
                console.log(this.dialogAddForm.fileList, '000000000000000000', this.arrFileList);
            } else if (res.code === "400") {
                this.$message.error(res.msg)
                this.$refs.uploadRef1.clearFiles()
                this.dialogAddForm.fileList = []
            }
        },
        /**
         * @Description: 添加 -- 会议纪要 -- 选择上传文件回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-02 21:11:11
         */
        uploadBefore1(file) {
            let isImg = [".doc", ".docx", ".xls", ".xlsx", ".txt", ".pdf", ".wps", ".jpg", ".png"].includes(file.name.substring(file.name.lastIndexOf(".")))
            let isLt10M = file.size / 1024 / 1024 < 50
            if (!isImg) {
                this.$message.error("上传文件格式错误！")
                return false
            } else if (!isLt10M) {
                this.$message.error("文件过大，请上传50M之内的文件。")
                return false
            }
        },
        /**
         * @Description: 添加-录屏-上传成功回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-02 20:54:11
         */
        uploadSuccess2(res, file) {
            if (res.code === "200") {
                this.arrVideoList.push({
                    name: file.name,
                    url: res.data.url
                })
                this.dialogAddForm.videoList = JSON.stringify(this.arrVideoList)
                console.log(this.dialogAddForm.videoList, '11111111111111111111', this.arrVideoList);
            } else if (res.code === "400") {
                this.$message.error(res.msg)
                this.$refs.uploadRef2.clearFiles()
                this.dialogAddForm.videoList = []
            }
        },
        /**
        * @Description: 添加 -- 录屏 -- 选择上传文件回调
        * @DoWhat:
        * @UseScenarios:
        * @Attention:
        * @Author: xwn
        * @Date: 2023-01-02 21:11:11
        */
        uploadBefore2(file) {
            let isTypes = [".wmv", ".asf", ".rm", ".rmvb", ".mov", ".avi", ".mp4"].includes(file.name.substring(file.name.lastIndexOf(".")))
            let isLt10M = file.size / 1024 / 1024 < 600
            if (!isTypes) {
                this.$message.error("上传视频格式错误！")
                return false
            } else if (!isLt10M) {
                this.$message.error("文件过大，请上传600M之内的文件。")
                return false
            }
        },
        /**
        * @Description: 纪要-上传成功回调
        * @DoWhat:
        * @UseScenarios:
        * @Attention:
        * @Author: xwn
        * @Date: 2023-01-02 20:54:11
        */
        uploadSuccess3(res, file) {
            if (res.code === "200") {
                this.getSummaryList()
            } else if (res.code === "400") {
                this.$message.error(res.msg)
                this.$refs.uploadRef.clearFiles()
                this.dialogSummary.file = []
            } else if (res && res.msg) {
                this.$message.warning(res.msg)
                this.$refs.uploadRef.clearFiles()
            }
        },
        /**
         * @Description:纪要 -- 选择上传文件回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-02 21:11:11
         */
        uploadBefore3(file) {
            let isImg = [".doc", ".docx", ".xls", ".xlsx", ".txt", ".pdf", ".wps", ".jpg", ".png"].includes(file.name.substring(file.name.lastIndexOf(".")))
            let isLt50M = file.size / 1024 / 1024 < 50
            if (!isImg) {
                this.$message.error("上传文件格式错误！")
                return false
            }
            if (!isLt50M) {
                this.$message.error("文件过大，请上传50M之内的文件。")
                return false
            }
        },
        /**
         * @Description: 上传文件 - 纪要
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-02 20:45:57
         */
        uploadChange3(file, fileList) {
            this.dialogSummary.file = fileList
        },
        /**
         * @Description: 预览文件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-03 15:30:03
         */
        handlePreview(data) {
            let videoType = ['swf', 'avi', 'flv', 'mpg', 'rm', 'mov', 'wav', 'asf', '3gp', 'mkv', 'rmvb', 'mp4']
            let url = data.url.split('/');
            url = url[url.length -1].split('.');
            url = url[url.length -1];

            if (data.url.indexOf('minio') !== -1 && videoType.indexOf(url) == -1) {
                let checkFilePreview = filePreview();
                checkFilePreview.openNewPage(data.url)
            } else {
                let url = decodeURIComponent(data.url) // 要预览视频文件的访问地址
                let Base64 = require("js-base64").Base64
                let previewUrl = `http://124.71.235.241:8012/onlinePreview?url=${encodeURIComponent(Base64.encode(url))}`
                window.open(previewUrl)
            }
            // let checkFilePreview = filePreview();
            // checkFilePreview.openNewPage(data.url)
        },
        /**
         * @Description: 预览视频
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-04 16:33:05
         * @param {*} data
         */
        handlePreviewVideo(data) {
            console.log(data.url, '000000');
            this.dialogVisible = !this.dialogVisible
            this.$nextTick(() => {
                this.player = new Player({
                    id: 'mse',
                    url: data.url
                });
            })
        },
        /**
         * @Description: 关闭视频预览 弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-06-29 14:07:36
         */
        closePreviewVideo(data) {
            console.log(data.url, '关闭视频预览 弹窗');
            this.dialogVisible = !this.dialogVisible
            this.$nextTick(() => {
                this.player.destroy(true) // 参数 isDelDom: true 删除内部DOM元素 | false 保留内部DOM元素，默认为true
            })
        },
        /**
         * @Description: 会议纪要-删除
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-03 10:36:52
         */
        handleDel(data) {
            this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this._fet("/school/schoolMeetingAttachment/delete", {
                    id: data.id,
                }).then((res) => {
                    if (res.data.code === "200") {
                        this.getSummaryList()
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {
            })
        },
        /**
         * @Description: 会议纪要/录屏--下载文件
         * @DoWhat: 通过url下载文件并自定义下载名称
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-02-13 15:01:40
         */
        downlad(urls, fileName) {
            this.$message({
                type: 'success',
                message: '文件下载中，请稍后。。。',
                duration: 5000
            });
            //判断文件名称是否带有后缀，没有后缀添加一个.MP4的后缀（解决自动上传的视频无后缀下载无法播放问题）
            var newFileName = fileName.split(".");
            newFileName = newFileName[newFileName.length - 1];
            if (fileName === newFileName) {
                // fileName = fileName + '.mp4'
                fileName = fileName + urls.match(/.[^.]+$/)[0]
            }
            const x = new window.XMLHttpRequest();
            x.open('GET', urls, true);
            x.responseType = 'blob';
            x.onload = () => {
                const url = window.URL.createObjectURL(x.response);
                const a = document.createElement('a');
                a.href = url;
                a.target = '_blank'
                a.download = fileName;
                a.style.display = 'none'
                document.body.append(a)
                a.click();
            };
            x.send();
        },
        /**
        * @Description: 录屏-上传成功回调
        * @DoWhat:
        * @UseScenarios:
        * @Attention:
        * @Author: xwn
        * @Date: 2023-01-02 20:54:11
        */
        uploadSuccess4(res, file) {
            if (res.code === "200") {
                this.getScreenList()
            } else if (res.code === "400") {
                this.$message.error(res.msg)
                this.$refs.uploadRef.clearFiles()
            } else if (res && res.msg) {
                this.$message.warning(res.msg)
                this.$refs.uploadRef.clearFiles()
            }
        },
        /**
         * @Description:录屏 -- 选择上传文件回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-02 21:11:11
         */
        uploadBefore4(file) {
            let isTypes = [".wmv", ".asf", ".rm", ".rmvb", ".mov", ".avi", ".mp4"].includes(file.name.substring(file.name.lastIndexOf(".")))
            let isLt10M = file.size / 1024 / 1024 < 600
            if (!isTypes) {
                this.$message.error("上传视频不符合格式！")
                return false
            } else if (!isLt10M) {
                this.$message.error("文件过大，请上传600M之内的文件。")
                return false
            }
        },
        /**
         * @Description: 录屏 -- 上传文件
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-02 20:45:57
         */
        uploadChange4(file, fileList) {
            this.dialogSummary.file = fileList
        },
        handleScreenDel(data) {
            this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this._fet("/school/schoolMeetingAttachment/delete", {
                    id: data.id,
                }).then((res) => {
                    if (res.data.code === "200") {
                        this.getScreenList()
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg)
                    }
                })
            }).catch(() => {
            })
        },
        /**
         * @Description: 查询/添加
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-12-29 11:56:52
         * @param {*} type
         */
        clickBtn(type) {
            switch (type.item.fn) {
                case "primary": // 查询
                    this.listQuery.conferenceSponsor = '';
                    this.formData.data.forEach((item) => {
                        this.listQuery[item.key] = item.value;
                    });
                    if (!this.listQuery['selectTime']) {
                        this.listQuery['selectTime'] = [];
                    }
                    
                    this.getTableList(1);
                    break;
                case 'reset': //重置
                    this.formData.data.forEach((item) => {
                        if(item.key === 'organIds'){
							item.value = [];
							this.listQuery[item.key] = [];
						}else{
							item.value = "";
					        this.listQuery[item.key] = "";
						}
                    })
                    this.listQuery.conferenceSponsor = '';
                    this.listQuery['selectTime'] = [];
                    this.listQuery['organIds'] = [];
                    this.getTableList(1)
                    break;
            }
        },
        changeGetList(){
            this.$nextTick(() => {
                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = 0
            });
            this.getTableList()
        },
        /**
         * @Description: 获取表格数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-09 11:04:23
         */
        getTableList(t) {
            const FULL_YEAR = new Date().getFullYear();

            if (t === 1) {
                this.listQuery.pageNum = 1
            }
            this.loadingTable = true
            this.listQuery.schoolId = this.$store.state.schoolId
            this.listQuery.startTime = this.listQuery.startTime !== "" ? this.listQuery.startTime : null,
                this.dataList.forEach((i) => {
                    if (this.listQuery.sponsorUnionId === i.dingUnionId) {
                        this.listQuery.conferenceSponsor = i.teacherName
                    }
                })
            delete this.listQuery.sponsorUnionId
            console.log(this.listQuery, this.listQuery.sponsorUnionId, this.dataList, '333333333333');
            this._fet("/school/schoolMeetingAnalysis/list", this.listQuery).then(res => {
                if (res.data.code === "200") {
                    setTimeout(() => {
                        this.total = res.data.data.totalCount
                        this.table_data = res.data.data.list
                        //修改列表预约时间的格式
                        this.table_data.map(i => {
                            i['_startTime'] = i.startTime;
                            let appointTime = this.computedTime(i.appointStartTime, i.appointEndTime)
                            this.$set(i, 'appointTime', appointTime)
                            i.startTime.slice(0, 4) == this.yearsDeta ?  this.$set(i, 'startDate', i.startTime.slice(5, 10))  :  this.$set(i, 'startDate', i.startTime.slice(0, 10))

                            if(i.status == 2){
                                let appointTime1 = this.computedTime(i.startTime, i.endTime)
                                this.$set(i, 'startTime', appointTime1.split(' ')[1])
                            }
                        })
                        // 手动添加的会议日期和会议时长需要计算,通过mode为5区分是否为手动
                        this.table_data.forEach((item) => {

                            if (item.mode == '5' && item._startTime) {
                                let _copy_startTime = item._startTime;
                                let year = _copy_startTime.split('-')[0];
                                let yearMonDay = _copy_startTime.split(' ')[0];
                                let meetingStart = _copy_startTime.split(' ')[1];

                                if (year == FULL_YEAR) {
                                    item['startDate'] = _copy_startTime.split(' ')[0].split('-').slice(1).join('-');
                                } else {
                                    item['startDate'] = yearMonDay
                                }

                                if (meetingStart && item.meetingDuration) {
                                    item['startTime'] = this.secToTime(this.timeToSec(meetingStart)) + '-' +this.secToTime(this.timeToSec(meetingStart) + this.timeToSec(item.meetingDuration))
                                } else {
                                    item['startTime'] = '-'
                                }
                            }
                        })
                        //获取--存储筛选项及滚动条高度
                        this.$nextTick(() => {
                            let recordsList =  new RecordsList()
                            if(recordsList.get(this.$route.name)){
                                this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop = recordsList.get(this.$route.name).tableScrollTop
                            }
                            // console.log('获取--存储筛选项及滚动条高度',recordsList.get(this.$route.name).tableScrollTop);
                            //移除--存储筛选项及滚动条高度
                            recordsList.remove(this.$route.name)
                        });
                    }, 200)
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
                this.loadingTable = false
            })
        },
        /**
         * @Description: 时分秒转换为秒
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-11-24 14:14:22
         */
        timeToSec (time) {
            let h = time.split(':')[0],
               m = time.split(':')[1],
               s = time.split(':')[2];

            return Number(h) * 3600 + Number(m) * 60 + Number(s);
        },
        secToTime(time) {
            let t;
            if(time > -1){
                let hour = Math.floor(time/3600);
                let min = Math.floor(time/60) % 60;
                // let sec = time % 60;

                if(hour < 10) {
                    t = '0'+ hour + ":";
                } else {
                    t = hour + ":";
                }

                if(min < 10){
                    t += "0";
                }
                t += min;

                // if(sec < 10){
                //     t += "0";
                // }
                // t += sec.toFixed(0);
            }
            return t;
        },
        /**
       * @Description: 处理预约时间的显示格式
       * @DoWhat:
       * @UseScenarios:
       * @Attention:
       * @Author: xwn
       * @Date: 2023-01-02 20:54:11
       */
        computedTime(startTime, endTime) {
            let time = "";
            if (startTime && endTime) {
                let a = endTime.slice(0, endTime.length - 3);
                time += startTime.slice(0, startTime.length - 3);
                time = time + '-' + a.split(' ')[1]
            }
            return time
        },
        /**
         * @Description: 获取科目列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-09 14:46:01
         */
        getSubjectList() {
            this._fet("/school/schoolDictValue/getDictValueList", {
                schoolId: this.$store.state.schoolId,
                dickKeyList: ["teachingSubjects"],
            }).then((res) => {
                if (res.status === 0) {
                    setTimeout(() => {
                        this.subjectType = res.data.map(i => ({ label: i.name, value: i.id }))
                        this.formData.data.forEach((item) => {
                            if (item.key === 'subjectId') {
                                item.list = res.data.map(i => ({ label: i.name, value: i.id }))
                                // item.list.unshift({label: '科目', value: ''})
                            }
                        })
                    }, 200)

                }
            })
        },
        /**
         * @Description: 表格按钮点击操作
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 马枫茂
         * @Date: 2022-11-09 15:41:38
         */
        tableBtnClick(data, type) {
            //存储筛选项及滚动条高度
            let recordsList =  new RecordsList()
            recordsList.set(this.$route.name, {
                formData: this.formData,
                listQuery:  this.listQuery,
                tableScrollTop: this.$refs.table.$refs.multipleTable.bodyWrapper.scrollTop
            })
            if (type === "primary") {
                // 查看详情跳转
                sessionStorage.setItem('is_edit', "");
                sessionStorage.setItem('meeting_type', data.modeName);
                this.$emit("switchPage", data.id)
            } else if (type === 'edit') {
                // 编辑跳转
                sessionStorage.setItem('is_edit', "edit");
                sessionStorage.setItem('meeting_type', data.modeName);
                this.$emit("switchPage", data.id)
            } else if (type === "danger") {
                this.$confirm("此操作将永久删除, 是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning"
                }).then(() => {
                    let meetingListModel = new MeetingListModel()
                    let formData = new FormData()
                    formData.append("ids", data.id)
                    meetingListModel.delByIds(formData).then(res => {
                        if (res.data.code === "200") {
                            this.getTableList()
                        } else if (res.data && res.data.msg) {
                            this.$message.error(res.data.msg)
                        }
                    })
                }).catch(() => {
                })
            }
        },
        /**
         * @Description: 纪要 -- 打开纪要弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-12-30 15:35:46
         */
        handleSummary(data) {
            this.dialogSummary.meetingId = data.id
            this.getSummaryList()
            this.dialogObjSummary.dialogVisible = true
        },
        /**
         * @Description: 获取会议纪要列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-01-03 11:06:14
         */
        getSummaryList() {
            this._fet("/school/schoolMeetingAttachment/listByCondition", {
                meetingId: this.dialogSummary.meetingId,
                type: "1",
            }).then((res) => {
                if (res.data.code === "200") {
                    this.tableData = res.data.data
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 纪要 -- 关闭纪要弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-12-30 15:38:01
         */
        handleCloseSummary() {
            this.$refs.uploadRef.clearFiles()
            this.$refs.dialogSummaryRef.resetFields()
            this.dialogObjSummary.dialogVisible = false
        },
        /**
        * @Description: 录屏 -- 打开录屏弹框
        * @DoWhat:
        * @UseScenarios:
        * @Attention:
        * @Author: xwn
        * @Date: 2022-12-30 15:35:46
        */
        handleScreen(data) {
            this.dialogScreen.meetingId = data.id
            this.getScreenList()
            this.dialogObjScreen.dialogVisible = true
        },
        getScreenList() {
            this._fet("/school/schoolMeetingAttachment/listByCondition", {
                meetingId: this.dialogScreen.meetingId,
                type: "2",
            }).then((res) => {
                if (res.data.code === "200") {
                    this.tableScreenData = res.data.data
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            })
        },
        /**
         * @Description: 纪要 -- 关闭纪要弹框
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2022-12-30 15:38:01
         */
        handleCloseScreen() {
            this.$refs.uploadRef.clearFiles()
            this.$refs.dialogScreenRef.resetFields()
            this.dialogObjScreen.dialogVisible = false
        },
        changeCascader(data) {
        },

    }
}
</script>

<style lang="scss" scoped>
.meeting-list {
    .content-wrapper {
        padding: 10px;
        box-sizing: border-box;
    }

    .filter-bar {
        display: flex;
        padding: 15px 20px;
        border-radius: 6px;
        align-items: center;
        margin-bottom: 10px;
        background-color: #ffffff;
        // justify-content: space-between;

        .filter-item {
            display: flex;
            margin-right: 40px;
            align-items: center;

            span {
                color: #808487;
                font-size: 14px;
                margin-right: 10px;
            }
        }
    }

    .filter-head {
        margin-bottom: 0 !important;

        /deep/ .el-form-item__label {
            color: #808487;
            line-height: 36px;
        }
    }

    .table-paging {
        overflow: hidden;
        border-radius: 6px;
        background-color: #ffffff;
    }

    // 表格更多按钮
    .el-dropdown .el-button {
        border: 0;
        color: #3C7FFF;
        padding: 8px 15px;
        margin-left: 12px;
        background-color: #edf4fa;
    }

    .screen-btn {
        margin-right: 12px;
    }


}

.filter-wrap {
    display: flex;
    align-items: flex-start;
    background-color: #fff;
    padding: 10px 10px 0;
    margin-bottom: 10px;
    border-radius: 4px;
    overflow: hidden;

    .expand-filter {
        padding: 0;
    }
}

.more-btn {
    padding: 6px 8px;
}

.more-btn .button {
    margin-left: 0;
    margin-right: 0;
}
.spanTip{
        margin:0px 100px 5px 100px;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #F56C6C;
    }
    .input_inner_right  .el-input__inner{
        padding-left: 30px;
    }
</style>
<style lang="scss">
.el-picker-panel.no_now {
    .el-button--text.el-picker-panel__link-btn {
        display: none;
    }
}

</style>
